/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { IProcessResource, isDeploymentProcessResource, isRunbookProcessResource } from "client/resources/deploymentProcessResource";
import { generateBasicScriptStep, generateGuid, generateBasicParentStep, getInlineScriptProperties } from "../generation";
import { ScriptingLanguage } from "components/scriptingLanguage";
import DeploymentStepResource, { StartTrigger, RunCondition } from "client/resources/deploymentStepResource";
import { repository, client } from "clientInstance";
import { clone, keyBy, isEqual, cloneDeep, identity, flatMap, Dictionary } from "lodash";
import { ActionTemplateResource } from "client/resources/actionTemplateResource";
import pluginRegistry, { ActionPlugin, ActionScope } from "components/Actions/pluginRegistry";
import {
    ActionExecutionLocation,
    WorkerPoolResource,
    WorkerPoolsSummaryResource,
    WorkerPoolSummaryResource,
    Permission,
    DeploymentActionResource,
    OctopusError,
    ProjectResource,
    RunbookProcessResource,
    ProcessType,
    PropertyValueResource,
    NonVcsRunbookResource,
    RunbookResource,
} from "client/resources";
import FeedResource from "client/resources/feedResource";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import Environment from "environment";
import { DeploymentActionContainer } from "client/resources/deploymentActionContainer";
import { StoredAction, StoredStep, RunOn, RunOnDeploymentTarget, ExecutionLocation, TargetRoles, AssembledAction, ProcessState, RunOnWorkerPool, RunOnBuiltInWorker, RunOnServerOrWorkerPool } from "../types";
import { ProcessContextProviderSetupActions } from "../Contexts/ProcessContext";
import ActionButton, { ActionButtonType } from "components/Button";
import React from "react";
import { noOp } from "utils/noOp";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import ActionList from "components/ActionList";
import { DeleteStepsCallout } from "./DeleteStepsCallout";
import routeLinks from "routeLinks";
import { MenuItem } from "components/Menu/OverflowMenu";
import { BoundErrorActionsType } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import { BoundWarningActionsType } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessQueryStringContextProviderSetupActions } from "../Contexts/ProcessQueryString/ProcessQueryStringContext";
import { createDefaultStepResource, createDefaultActionResource, ProcessStateSelectors } from "../Contexts/ProcessContextState";
import { typeSafeHasOwnProperty } from "client/utils";
import ProjectContextRepository from "client/repositories/projectContextRepository";

export function isRunbookProcessState(resource: ProcessState | null | undefined): resource is NonNullable<RunbookProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }

    const converted = resource as RunbookProcessResource;
    return converted.RunbookId !== undefined && typeSafeHasOwnProperty(converted, "RunbookId");
}

export const getProcessTypeFromResource = (resource: IProcessResource) => {
    if (isDeploymentProcessResource(resource)) {
        return ProcessType.Deployment;
    } else if (isRunbookProcessResource(resource)) {
        return ProcessType.Runbook;
    }
};

//export function isDeploymentProcessState(resource: ProcessState | null | undefined): resource is NonNullable<

export function deleteActionAndRedirect(
    step: StoredStep,
    action: StoredAction | undefined,
    isSelected: boolean,
    contextActions: ProcessContextProviderSetupActions,
    contextSelectors: ProcessStateSelectors,
    queryStringActions: ProcessQueryStringContextProviderSetupActions
) {
    //TODO: Investigate if there is a way to do this without resorting to this abomination.
    const removeFromContext = () =>
        setImmediate(() => {
            !!action ? contextActions.removeAction(step.Id, action.Id) : contextActions.removeStep(step.Id);
        });

    if (contextSelectors.getAllActions().length === 1) {
        removeFromContext();
        queryStringActions.showEmptyStepEditor();
    } else if (isSelected) {
        const nextAction = contextSelectors.getAllActions().find((x) => x.ParentId !== step.Id && x.Id !== action?.Id);
        removeFromContext();
        if (!nextAction) {
            queryStringActions.showEmptyStepEditor();
        } else {
            queryStringActions.showProcessAction(nextAction.Id);
        }
    } else {
        removeFromContext();
    }
}

export function getCommonOverflowMenuItems(
    repository: ProjectContextRepository,
    project: ProjectResource,
    runbook: RunbookResource | undefined,
    processType: ProcessType,
    processContextSelectors: ProcessStateSelectors,
    processContextActions: ProcessContextProviderSetupActions,
    errorActions: BoundErrorActionsType,
    warningActions: BoundWarningActionsType,
    redirectToList?: () => void
) {
    const overflowMenuItems: Array<MenuItem | MenuItem[]> = [];
    if (processContextSelectors.hasValidProcess()) {
        if (!processContextSelectors.hasSteps()) {
            overflowMenuItems.push(
                OverflowMenuItems.item(
                    "Load a sample process",
                    async () => {
                        // This is a round-trip operation to the server, NOT to the context.
                        const sampleProcess = addSampleStepsToProcessResource(processContextSelectors.getProcessResource());
                        await processContextActions.saveOnServer(
                            repository,
                            sampleProcess,
                            (errors) => {
                                errorActions.setErrors(errors, processContextSelectors);
                                // The save action will give us errors only, clear any warnings.
                                warningActions.clearWarnings();
                            },
                            () => {
                                errorActions.clearErrors();
                                warningActions.clearWarnings();
                            }
                        );
                    },
                    {
                        permission: processScopedEditPermission(processType),
                        project: project.Id,
                        wildcard: true,
                    }
                )
            );
            if (Environment.isInDevelopmentMode()) {
                overflowMenuItems.push(
                    OverflowMenuItems.item(
                        "Load a large sample process (dev only)",
                        async () => {
                            // This is a round-trip operation to the server, NOT to the context.
                            const sampleProcess = addLargeSampleStepsToProcessResource(processContextSelectors.getProcessResource());
                            await processContextActions.saveOnServer(
                                repository,
                                sampleProcess,
                                (errors) => {
                                    errorActions.setErrors(errors, processContextSelectors);
                                    // The save action will give us errors only, clear any warnings.
                                    warningActions.clearWarnings();
                                },
                                () => {
                                    errorActions.clearErrors();
                                    warningActions.clearWarnings();
                                }
                            );
                        },
                        {
                            permission: processScopedEditPermission(processType),
                            project: project.Id,
                            wildcard: true,
                        }
                    )
                );
            }
        }
        if (processContextSelectors.hasSteps()) {
            overflowMenuItems.push(
                processType === ProcessType.Runbook
                    ? OverflowMenuItems.downloadItem(
                          "Download as JSON",
                          `${project.Slug}-${runbook?.Name}-process.json`,
                          client.resolveLinkTemplate("RunbookProcesses", {
                              id: processContextSelectors.getStoredProcess().Id,
                          })
                      )
                    : OverflowMenuItems.downloadItem(
                          "Download as JSON",
                          `${project.Slug}-process.json`,
                          client.resolveLinkTemplate("DeploymentProcesses", {
                              id: processContextSelectors.getStoredProcess().Id,
                          })
                      )
            );
            overflowMenuItems.push(
                OverflowMenuItems.deleteItem(
                    "Delete all steps",
                    "Are you sure you want to delete all steps from this process?",
                    async () => {
                        // Delete steps from our processResource directly and save on server. That will cause our context to get a new process.
                        const processToSave = processContextSelectors.getProcessResource() as IProcessResource;
                        processToSave.Steps = [];
                        await processContextActions.saveOnServer(
                            repository,
                            processToSave,
                            (errors) => {
                                errorActions.setErrors(errors, processContextSelectors);
                                // The save action will give us errors only, clear any warnings.
                                warningActions.clearWarnings();
                            },
                            () => {
                                errorActions.clearErrors();
                                warningActions.clearWarnings();
                            }
                        );

                        if (redirectToList) {
                            redirectToList();
                        }

                        return true;
                    },
                    () => <DeleteStepsCallout />,
                    {
                        permission: processScopedEditPermission(processType),
                        project: project.Id,
                        wildcard: true,
                    },
                    false
                )
            );
        }
        overflowMenuItems.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([processContextSelectors.getStoredProcess().Id]), undefined, {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
    }
    return overflowMenuItems;
}

export const convertProcessTypeToActionScope = (processType: ProcessType) => {
    switch (processType) {
        case ProcessType.Deployment:
            return ActionScope.Deployments;
        case ProcessType.Runbook:
            return ActionScope.Runbooks;
    }
    throw Error("Invalid process type provided");
};

export const getPlaceholderActionList = (processType: ProcessType) => {
    // UX: We display some placeholder actions to aid with transitions between our loading and layout.
    const placeholderAddButton = <ActionButton type={ActionButtonType.Secondary} label="Add Step" onClick={noOp} disabled={true} />;
    const placeholderOverflowMenu = <OverflowMenu menuItems={[OverflowMenuItems.disabledItem("loading...", "")]} />;
    let actions: JSX.Element[] = [];
    if (processType === ProcessType.Runbook) {
        const placeholderRunButton = <ActionButton type={ActionButtonType.Secondary} label="Run..." onClick={noOp} disabled={true} />;
        actions = [placeholderRunButton, placeholderAddButton, placeholderOverflowMenu];
    } else {
        actions = [placeholderAddButton, placeholderOverflowMenu];
    }
    return <ActionList actions={actions} />;
};

export const generateDefaultActionContainer = (): DeploymentActionContainer => {
    return { FeedId: null, Image: null };
};

function getPossibleFeatures(plugin: ActionPlugin): { [key: string]: string } {
    if (!plugin.features) {
        return {};
    }
    return keyBy([...(plugin.features.initial || []), ...(plugin.features.optional || []), ...(plugin.features.permanent || [])], (x) => x);
}

/*TODO SM: feature.validate currently has no equivalent call location */
/*TODO SM: feature.disable*/
export function validateFeatures<T extends DeploymentActionResource | StoredAction>(action: Readonly<T>, processType: ProcessType, plugin: ActionPlugin): T {
    const result = cloneDeep(action) as T;

    const scope = convertProcessTypeToActionScope(processType);
    const permanentFeatures = plugin.features?.permanent ?? [];
    const enabledFeatureNames = ((result.Properties["Octopus.Action.EnabledFeatures"] as string) || "").split(",").filter((name) => {
        return name !== "";
    });
    const enabledFeatureLookup = keyBy(enabledFeatureNames, identity);

    const missingPermanentFeatures = permanentFeatures.filter((x) => !enabledFeatureLookup.hasOwnProperty(x));
    const applicableFeatureNames = [...missingPermanentFeatures, ...enabledFeatureNames];

    const possibleFeatures = getPossibleFeatures(plugin);

    const enabledFeatures = applicableFeatureNames.map((f) => {
        return pluginRegistry.getFeature(f, scope);
    });

    const errors = {};

    enabledFeatures.forEach((feature) => {
        if (feature.validate) {
            feature.validate(result.Properties, errors);
        }
    });

    if (Object.keys(errors).length > 0) {
        const exception = new OctopusError(0, "There was a problem with your request.");
        exception.Errors = Object.values(errors);
        exception.Details = errors;
        throw exception;
    }

    const properties = { ...result.Properties };
    pluginRegistry.getAllFeatures(scope).forEach((feature) => {
        if (applicableFeatureNames.indexOf(feature.featureName) === -1) {
            if (feature.disable && possibleFeatures.hasOwnProperty(feature.featureName)) {
                feature.disable(properties);
            }
        }
    });

    if (applicableFeatureNames.length > 0) {
        properties["Octopus.Action.EnabledFeatures"] = applicableFeatureNames.join(",");
    }

    result.Properties = properties;
    return result;
}

export function loadAvailableWorkerPools(workerPoolsSummary: WorkerPoolsSummaryResource): WorkerPoolResource[] {
    const availableWorkerPools: WorkerPoolResource[] = [];
    if (workerPoolsSummary.WorkerPoolSummaries.length === 1) {
        if (workerPoolsSummary.WorkerPoolSummaries[0].WorkerPool.IsDefault && workerPoolsSummary.WorkerPoolSummaries[0].TotalMachines === 0 && workerPoolsSummary.WorkerPoolSummaries[0].WorkerPool.CanAddWorkers) {
            return availableWorkerPools;
        }
    }
    workerPoolsSummary.WorkerPoolSummaries.forEach((workerPoolSummary: WorkerPoolSummaryResource) => {
        availableWorkerPools.push(workerPoolSummary.WorkerPool);
    });
    return availableWorkerPools;
}

function hasImageBeenSelected(container: DeploymentActionContainer | undefined) {
    return container?.Image ? true : false;
}

export function runsOnServer(action: StoredAction | DeploymentActionResource, executionLocation: ActionExecutionLocation): boolean {
    return action && executionLocation && (executionLocation === ActionExecutionLocation.AlwaysOnServer || (executionLocation === ActionExecutionLocation.TargetOrServer && action.Properties["Octopus.Action.RunOnServer"] === "true"));
}

export function isRunOnDeploymentTarget(runOn: RunOn): runOn is RunOnDeploymentTarget {
    return runOn.executionLocation === ExecutionLocation.DeploymentTarget;
}

export function isRunOnWorkerPool(runOn: RunOn): runOn is RunOnWorkerPool {
    const converted = runOn as RunOnWorkerPool;
    return runOn.executionLocation === ExecutionLocation.WorkerPool && hasProperty(converted, "container") && hasProperty(converted, "runningInContainer");
}

export function isRunOnBuiltInWorker(runOn: RunOn): runOn is RunOnBuiltInWorker {
    const converted = runOn as RunOnBuiltInWorker;
    return runOn.executionLocation === ExecutionLocation.OctopusServer && hasProperty(converted, "container") && hasProperty(converted, "runningInContainer");
}

export function isRunOnWorkerPoolForRoles(runOn: RunOn): runOn is RunOnWorkerPool {
    const converted = runOn as RunOnWorkerPool;
    return runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles && hasProperty(converted, "container") && hasProperty(converted, "runningInContainer");
}

export function isRunOnServerOrWorkerPool(runOn: RunOn): runOn is RunOnServerOrWorkerPool {
    return !isRunOnDeploymentTarget(runOn);
}

export function doesRunOnSupportBundledTools(runOn: RunOn | null | undefined): boolean {
    if (runOn === undefined || runOn === null) {
        return true;
    }
    //We want to show the tools for everything except RunOnDeploymentTarget, this guard automatically narrows the type appropriately.
    return isRunOnServerOrWorkerPool(runOn) && !runOn.runningInContainer;
}

export function whereToRun(stepHasRoles: boolean, action: StoredAction | DeploymentActionResource | null, availableWorkerPools: WorkerPoolResource[], plugin: ActionPlugin, isBuiltInWorkerEnabled: boolean): RunOn {
    //TODO: we have a bit of friction between these common process helpers and what we have in the context selectors. For example whether a plugin can run on Worker. It would be worthwhile
    //to investigate what it would take to move RunOn into the stored action model in the context in order to avoid checking these properties each and every time. It would be better
    //if we can work with a view model and only translate when loading the original process and when saving.
    if (!action) {
        return new RunOnDeploymentTarget();
    }

    const runsOnServerValue = runsOnServer(action, plugin.executionLocation);
    if (!runsOnServerValue) {
        return new RunOnDeploymentTarget();
    }

    if (availableWorkerPools.length > 0 && (plugin.canRunOnWorker === true || plugin.canRunOnWorker === undefined)) {
        return {
            executionLocation: showRolesForServer(stepHasRoles, action, plugin) ? ExecutionLocation.WorkerPoolForRoles : ExecutionLocation.WorkerPool,
            container: action.Container ? action.Container : generateDefaultActionContainer(),
            runningInContainer: hasImageBeenSelected(action.Container),
        };
    } else if (isBuiltInWorkerEnabled) {
        return {
            executionLocation: showRolesForServer(stepHasRoles, action, plugin) ? ExecutionLocation.OctopusServerForRoles : ExecutionLocation.OctopusServer,
            container: action.Container ? action.Container : generateDefaultActionContainer(),
            runningInContainer: hasImageBeenSelected(action.Container),
        };
    } else {
        return new RunOnDeploymentTarget();
    }
}

function showRolesForServer(stepHasRoles: boolean, action: StoredAction | DeploymentActionResource, plugin: ActionPlugin) {
    return stepHasRoles || (plugin && plugin.targetRoleOption(action) === TargetRoles.Required);
}

export function processScopedEditPermission(processType: ProcessType): Permission {
    const isRunbook = processType === ProcessType.Runbook;
    return isRunbook ? Permission.RunbookEdit : Permission.ProcessEdit;
}

function pluginHasPackages(actionDefinition: ActionPlugin, action: StoredAction) {
    return actionDefinition.hasPackages ? actionDefinition.hasPackages(action) : false;
}

function getNormalizedAction(isNew: boolean, action: Readonly<StoredAction>, plugin: ActionPlugin, feeds: FeedResource[]): StoredAction {
    if (isNew && pluginHasPackages(plugin, action) && feeds.length === 0) {
        throw new Error("A package feed is required before that step can be configured. Please add a feed in the Configuration area and try again.");
    }

    const result: StoredAction = { ...action };

    // Provide default fallback for script templates.
    if (result.ActionType === "Octopus.Script" && result.Properties && result.Properties["Octopus.Action.RunOnServer"] == null) {
        result.Properties["Octopus.Action.RunOnServer"] = "false";
    }

    // If the execution location is optional, default it to run on the target
    if (isNew && plugin.executionLocation === ActionExecutionLocation.TargetOrServer) {
        result.Properties["Octopus.Action.RunOnServer"] = "false";
    }

    // If the execution location is locked, clean up the mess we've made by accidentally setting "Octopus.Action.RunOnServer" for all actions
    if (plugin.executionLocation !== ActionExecutionLocation.TargetOrServer) {
        delete result.Properties["Octopus.Action.RunOnServer"];
    }

    return result;
}

export function enableNewActionFeatures(action: Readonly<StoredAction>, plugin: ActionPlugin, scope: ActionScope) {
    const result: StoredAction = { ...action };

    const existingEnabledFeatures: PropertyValueResource = action.Properties["Octopus.Action.EnabledFeatures"] || "";
    const enabledFeatures = [...(typeof existingEnabledFeatures === "string" ? existingEnabledFeatures.split(",") : [])];

    const featuresToEnable = [...(plugin.features?.permanent ?? []), ...(plugin.features?.initial ?? [])];
    featuresToEnable.forEach((feature) => {
        const featurePlugin = pluginRegistry.getFeature(feature, scope);
        if (featurePlugin.enable) {
            featurePlugin.enable(result.Properties);
        }
    });

    result.Properties["Octopus.Action.EnabledFeatures"] = [...enabledFeatures, ...featuresToEnable].join(",");

    return result;
}

function getNormalizedStep(step: Readonly<StoredStep>): StoredStep {
    const result: StoredStep = { ...step };

    if (!result.Condition) {
        result.Condition = RunCondition.Success;
    }

    return result;
}

export function applyActionTemplate(action: Readonly<StoredAction>, step: Readonly<StoredStep>, actionTemplate: Readonly<ActionTemplateResource>): { step: StoredStep; action: StoredAction } {
    const stepResult: StoredStep = { ...step, Name: actionTemplate.Name };
    const actionResult: StoredAction = { ...action, Name: actionTemplate.Name, Packages: clone(actionTemplate.Packages), Properties: clone(actionTemplate.Properties) };
    actionResult.Properties["Octopus.Action.Template.Id"] = actionTemplate.Id;
    actionResult.Properties["Octopus.Action.Template.Version"] = actionTemplate.Version.toString();

    actionTemplate.Parameters.forEach((parameter) => {
        if (typeof parameter.DefaultValue !== "undefined" && parameter.DefaultValue !== null && parameter.DefaultValue !== "") {
            if (parameter.DisplaySettings["Octopus.ControlType"] === "Sensitive") {
                if (typeof parameter.DefaultValue === "object") {
                    actionResult.Properties[parameter.Name] = {
                        HasValue: true,
                        NewValue: undefined,
                    };
                } else {
                    actionResult.Properties[parameter.Name] = {
                        HasValue: true,
                        NewValue: parameter.DefaultValue,
                    };
                }
                return;
            }
            actionResult.Properties[parameter.Name] = parameter.DefaultValue;
        }
    });

    return { step: stepResult, action: actionResult };
}

function addSampleStepsToProcessResource(process: IProcessResource): IProcessResource {
    const learnMoreMarkdown = "[Learn more about the types of steps available in Octopus](https://g.octopushq.com/OnboardingAddStepsLearnMore)";

    if (!process.Steps) {
        process.Steps = [];
    }

    const powerShellStep = generateBasicScriptStep("Hello world (using PowerShell)", ScriptingLanguage.PowerShell, `Write-Host 'Hello world, using PowerShell'\n\n#TODO: Experiment with steps of your own :)\n\nWrite-Host '${learnMoreMarkdown}'`);
    const cSharpStep = {
        ...generateBasicScriptStep("Hello World (using C#)", ScriptingLanguage.CSharp, `Console.WriteLine("Hello world, using C#");\n\n//TODO: Experiment with steps of your own :)\n\nConsole.WriteLine("${learnMoreMarkdown}");`),
        StartTrigger: StartTrigger.StartWithPrevious,
    };
    process.Steps.push(powerShellStep);
    process.Steps.push(cSharpStep);

    return process;
}

function addLargeSampleStepsToProcessResource(process: IProcessResource): IProcessResource {
    const learnMoreMarkdown = "[Learn more about the types of steps available in Octopus](https://g.octopushq.com/OnboardingAddStepsLearnMore)";

    if (!process.Steps) {
        process.Steps = [];
    }

    const powerShellStep = generateBasicScriptStep("Hello world (using PowerShell)", ScriptingLanguage.PowerShell, `Write-Host 'Hello world, using PowerShell'\n\n#TODO: Experiment with steps of your own :)\n\nWrite-Host '${learnMoreMarkdown}'`);
    const cSharpStep = {
        ...generateBasicScriptStep("Hello World (using C#)", ScriptingLanguage.CSharp, `Console.WriteLine("Hello world, using C#");\n\n//TODO: Experiment with steps of your own :)\n\nConsole.WriteLine("${learnMoreMarkdown}");`),
        StartTrigger: StartTrigger.StartWithPrevious,
    };
    process.Steps.push(powerShellStep);
    process.Steps.push(cSharpStep);

    if (Environment.isInDevelopmentMode()) {
        for (let i = 1; i < 50; i++) {
            if (i % 2 === 0) {
                const actionGenerator = (idGenerator: () => string) => {
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return (options: any) => ({
                        ...options,
                        Id: idGenerator(),
                        Name: options.Name,
                        Properties: { ...options.Properties, ...getInlineScriptProperties(ScriptingLanguage.PowerShell, "whatever") },
                    });
                };
                const sampleParent = generateBasicParentStep(generateGuid(), `ParentSample${i}`, [`Child${i}_1`, `Child${i}_2`, `Child${i}_3`], actionGenerator(generateGuid));
                sampleParent.Properties = {
                    ...sampleParent.Properties,
                    "Octopus.Action.RunOnServer": "false",
                    "Octopus.Action.TargetRoles": "region",
                };
                process.Steps.push(sampleParent);
            } else {
                const sampleScript = generateBasicScriptStep(`ScriptSample${i}`, ScriptingLanguage.PowerShell, `Write-Host 'Hello world, using PowerShell'\n\n#TODO: Experiment with steps of your own :)\n\nWrite-Host '${learnMoreMarkdown}'`);
                process.Steps.push(sampleScript);
            }
        }
    }

    return process;
}

const getActionTypeName = (actionType: string | undefined, actionTemplates: ActionTemplateSearchResource[], action: StoredAction | null) => {
    if (actionType) {
        return actionTemplates.find((x) => x.Type === actionType)?.Name ?? "";
    } else if (action) {
        return actionTemplates.find((x) => x.Type === action.ActionType)?.Name ?? "";
    } else {
        throw new Error("Failed to find actiontype");
    }
};

export function assembleParentStep(parentStepId: string, processContextSelectors: ProcessStateSelectors) {
    const step = processContextSelectors.getStepById(parentStepId);
    const pageTitle = step.Name;
    const result = { action: null, step: getNormalizedStep(step) };
    return {
        ...result,
        pageTitle,
    };
}

export async function assembleNewAction(actionType: string, scope: ActionScope, plugin: ActionPlugin, actionTemplates: ActionTemplateSearchResource[], templateId: string | undefined, feeds: FeedResource[]): Promise<AssembledAction> {
    const stepResource = createDefaultStepResource(() => createDefaultActionResource(actionType));
    const { Actions: actionResources, ...restOfStep } = stepResource;
    if (actionResources.length < 1) {
        throw new Error("Expecting an actionResource");
    }

    //TODO Evaluate action templates and don't reassign
    let step = { ...restOfStep, ActionIds: actionResources.map((a) => a.Id) };
    let action = { ...actionResources[0], ParentId: step.Id };
    const actionTypeName = getActionTypeName(actionType, actionTemplates, action);
    const pageTitle = "New child step";

    // New step = lookup and apply library step template if applicable.
    let actionTemplate: ActionTemplateResource | null = null;
    if (templateId) {
        actionTemplate = await repository.ActionTemplates.get(templateId);
    } else {
        const actionTemplateLookup = action && action.Properties["Octopus.Action.Template.Id"];
        if (actionTemplateLookup) {
            actionTemplate = await repository.ActionTemplates.get(actionTemplateLookup.toString());
        }
    }

    if (actionTemplate) {
        const appliedTemplateResults = applyActionTemplate(action, step, actionTemplate);
        step = appliedTemplateResults.step;
        action = appliedTemplateResults.action;
    }

    const normalizedAction = enableNewActionFeatures(getNormalizedAction(true, action, plugin, feeds), plugin, scope);
    const normalizedStep = getNormalizedStep(step);
    normalizedAction.Name = actionTypeName;

    return {
        action: normalizedAction,
        step: normalizedStep,
        actionTypeName,
        pageTitle,
    };
}

export function assembleExistingAction(actionId: string, processContextSelectors: ProcessStateSelectors, actionTemplates: ActionTemplateSearchResource[], feeds: FeedResource[]): AssembledAction {
    const action = processContextSelectors.getActionById(actionId);
    const step = processContextSelectors.getStepById(action.ParentId);
    const plugin = processContextSelectors.getActionPlugin(actionId);
    const pageTitle = step.Name;

    //if this is based on some template...
    const actionTypeName = actionTemplates.find((x) => x.Type === action.ActionType)?.Name ?? "";
    const normalizedAction = getNormalizedAction(false, action, plugin, feeds);
    const normalizedStep = getNormalizedStep(step);

    return {
        action: normalizedAction,
        step: normalizedStep,
        actionTypeName,
        pageTitle,
    };
}

export class NoMergeRequiredResult<T extends IProcessResource> {
    value: T;
    constructor(value: T) {
        this.value = value;
    }
}

export class MergedProcessResult<T extends IProcessResource> {
    value: T;
    constructor(value: T) {
        this.value = value;
    }
}

export type MergeProcessResultType<T extends IProcessResource> = NoMergeRequiredResult<T> | MergedProcessResult<T>;

function hasProperty<T extends {}>(item: T, property: keyof T): boolean {
    return Object.prototype.hasOwnProperty.call(item, property) && item[property] !== undefined;
}

class ChildActionPatch {
    stepId: string;
    actionId: string;
    constructor(stepId: string, actionId: string) {
        this.actionId = actionId;
        this.stepId = stepId;
    }
}

class StepPatch {
    stepId: string;
    constructor(stepId: string) {
        this.stepId = stepId;
    }
}

class AddStepPatch {
    previousStep: string | null;
    value: Readonly<DeploymentStepResource>;

    constructor(previousStep: string | null, value: DeploymentStepResource) {
        this.previousStep = previousStep;
        this.value = value;
    }
}

class AddChildActionPatch extends StepPatch {
    value: Readonly<DeploymentActionResource>;
    previousAction: string | null;

    constructor(stepId: string, previousAction: string | null, value: DeploymentActionResource) {
        super(stepId);
        this.previousAction = previousAction;
        this.value = value;
    }
}

class ModifyActionPatch {
    value: Readonly<DeploymentActionResource>;
    constructor(value: DeploymentActionResource) {
        this.value = value;
    }
}

class ModifyStepPatch {
    value: Readonly<DeploymentStepResource>;
    constructor(value: DeploymentStepResource) {
        this.value = value;
    }
}

class DeleteStepPatch extends StepPatch {}
class DeleteChildActionPatch extends ChildActionPatch {}

type ProcessPatchTypes = AddStepPatch | AddChildActionPatch | DeleteStepPatch | DeleteChildActionPatch | ModifyActionPatch | ModifyStepPatch;
type ProcessPatches = ProcessPatchTypes[];

const getStepLookup = (steps: DeploymentStepResource[]) => {
    return keyBy(steps, (x) => x.Id);
};

const getActionLookup = (steps: DeploymentStepResource[]) => {
    return keyBy(flatMap(steps.map((step) => step.Actions.map((action) => ({ stepId: step.Id, action })))), (x) => x.action.Id);
};

interface StepAndActionLookups {
    steps: Dictionary<DeploymentStepResource>;
    actions: Dictionary<{ action: DeploymentActionResource; stepId: string }>;
}

const getProcessLookups = (process: IProcessResource): StepAndActionLookups => {
    return {
        steps: getStepLookup(process.Steps),
        actions: getActionLookup(process.Steps),
    };
};

const getProcessDifferenceLookups = (previous: StepAndActionLookups, next: StepAndActionLookups) => {
    const addedSteps = Object.values(next.steps).filter((x) => !hasProperty(previous.steps, x.Id));
    const addedStepLookup = getStepLookup(addedSteps);
    const addedChildActions = Object.values(next.actions).filter((x) => !hasProperty(addedStepLookup, x.stepId) && !hasProperty(previous.actions, x.action.Id));
    const deletedSteps = Object.values(previous.steps).filter((x) => !hasProperty(next.steps, x.Id));
    const deletedStepLookup = keyBy(deletedSteps, (x) => x.Id);
    const deletedChildActions = Object.values(previous.actions).filter((x) => !hasProperty(next.actions, x.action.Id) && !hasProperty(deletedStepLookup, x.stepId));
    const deletedChildActionLookup = keyBy(deletedChildActions, (x) => x.action.Id);
    const intersectingSteps = getStepLookup(Object.values(previous.steps).filter((x) => !hasProperty(deletedStepLookup, x.Id)));
    const intersectingActions = Object.values(previous.actions).filter((x) => !hasProperty(deletedStepLookup, x.stepId) && !hasProperty(deletedChildActionLookup, x.action.Id));

    return {
        addedSteps: addedStepLookup,
        addedChildActions,
        deletedSteps: deletedStepLookup,
        deletedChildActions,
        intersectingSteps,
        intersectingActions,
    };
};

const isStepWithoutActionsEqual = (previous: DeploymentStepResource, next: DeploymentStepResource) => {
    //Don't compare the actions since these are already captured in the actual changeset as additions (usually.)
    const { Actions: previousActions, ...previousStep } = previous;
    const { Actions: nextActions, ...nextStep } = next;
    return isEqual(previous, next);
};

const getProcessPatches = (previous: IProcessResource, next: IProcessResource, server: IProcessResource) => {
    const previousLookups = getProcessLookups(previous);
    const nextLookups = getProcessLookups(next);
    const serverLookups = getProcessLookups(server);
    const localChanges = getProcessDifferenceLookups(previousLookups, nextLookups);

    const patches: ProcessPatches = [];

    Object.values(localChanges.addedSteps).forEach((x) => {
        const index = next.Steps.findIndex((s) => s.Id === x.Id);
        patches.push(new AddStepPatch(index >= 1 ? next.Steps[index - 1].Id : null, x));
    });

    Object.values(localChanges.addedChildActions).forEach((x) => {
        const childActions = nextLookups.steps[x.stepId].Actions;
        const index = childActions.findIndex((s) => s.Id === x.action.Id);
        patches.push(new AddChildActionPatch(x.stepId, index >= 1 ? childActions[index - 1].Id : null, x.action));
    });

    Object.values(localChanges.deletedSteps).forEach((x) => patches.push(new DeleteStepPatch(x.Id)));
    Object.values(localChanges.deletedChildActions).forEach((x) => patches.push(new DeleteChildActionPatch(x.stepId, x.action.Id)));

    Object.values(localChanges.intersectingSteps).forEach((previousStep) => {
        const nextStep = nextLookups.steps[previousStep.Id];
        const stepModifiedLocally = !isStepWithoutActionsEqual(previousStep, nextStep);
        const serverHasStep = hasProperty(serverLookups.steps, previousStep.Id);
        const serverModifiedStep = serverHasStep && !isStepWithoutActionsEqual(previousStep, serverLookups.steps[previousStep.Id]);

        //If we modified this locally and it hasn't been modified by the server then we can safely apply the step changes
        if (stepModifiedLocally && !serverModifiedStep) {
            patches.push(new ModifyStepPatch(nextStep));
        }
        //There are 2 potential conflicts here, the server doesn't know about the step in which case it has been deleted the other is the
        //the server modified the step. In both these cases, we won't apply the patch, but we may be able to return something here to
        //indicate this to the user.
    });

    Object.values(localChanges.intersectingActions).forEach((previousAction) => {
        const nextAction = nextLookups.actions[previousAction.action.Id];
        const isActionModifiedLocally = !isEqual(previousAction.action, nextAction.action);
        const serverHasAction = hasProperty(serverLookups.actions, previousAction.action.Id);
        const serverModifiedAction = serverHasAction && !isEqual(previousAction.action, serverLookups.actions[previousAction.action.Id].action);

        //If we modified this locally and it hasn't been modified by the server then we can safely apply the step changes
        if (isActionModifiedLocally && !serverModifiedAction) {
            patches.push(new ModifyActionPatch(nextAction.action));
            //Same conflicts apply as per steps.
        }
    });

    //We should also detect move of steps and actions if they aren't new.

    return patches;
};

const applyPatches = <T extends IProcessResource>(process: Readonly<T>, patches: ProcessPatches) => {
    const mergedProcess: T = cloneDeep(process);
    const lookups = getProcessLookups(mergedProcess);

    patches.forEach((change) => {
        if (change instanceof DeleteStepPatch) {
            const index = mergedProcess.Steps.findIndex((x) => x.Id === change.stepId);
            if (index >= 0) {
                mergedProcess.Steps.splice(index, 1);
            }
        } else if (change instanceof DeleteChildActionPatch) {
            const parentStep = mergedProcess.Steps.find((x) => x.Id === change.stepId);
            if (parentStep) {
                const index = parentStep.Actions.findIndex((x) => x.Id === change.actionId);
                if (index >= 0) {
                    parentStep.Actions.splice(index, 1);
                }
            }
        } else if (change instanceof AddStepPatch) {
            const previousStepIndex = change.previousStep === null ? 0 : mergedProcess.Steps.findIndex((x) => change.previousStep);
            if (previousStepIndex >= 0 && previousStepIndex + 1 === mergedProcess.Steps.length) {
                mergedProcess.Steps.splice(previousStepIndex + 1, 0, change.value);
            } else {
                mergedProcess.Steps.push(change.value);
            }
        } else if (change instanceof AddChildActionPatch) {
            const step = mergedProcess.Steps.find((x) => x.Id === change.stepId);
            if (step) {
                const previousActionIndex = change.previousAction === null ? 0 : step.Actions.findIndex((x) => change.previousAction);
                if (previousActionIndex >= 0 && previousActionIndex + 1 === step.Actions.length) {
                    step.Actions.splice(previousActionIndex + 1, 0, change.value);
                } else {
                    step.Actions.push(change.value);
                }
            }
        } else if (change instanceof ModifyActionPatch) {
            const action = lookups.actions[change.value.Id];
            Object.assign(action.action, change.value);
        } else if (change instanceof ModifyStepPatch) {
            const step = lookups.steps[change.value.Id];
            const { Actions, ...rest } = change.value;
            Object.assign(step, rest);
        }
    });

    //TODO: If the parent step doesn't exist anymore but we have a child action, then we should try and add a regular step.

    //If we ended up deleting multiple child actions and none are left then we need to also delete the parent step.
    mergedProcess.Steps = mergedProcess.Steps.filter((x) => x.Actions.length > 0);
    return mergedProcess;
};

export const mergeProcesses = <T extends IProcessResource>(clientCleanProcess: Readonly<T>, clientProcess: Readonly<T>, serverProcess: Readonly<T>) => {
    if (clientProcess.Version === serverProcess.Version) {
        return new NoMergeRequiredResult(clientProcess);
    }

    const localChanges = getProcessPatches(clientCleanProcess, clientProcess, serverProcess);
    const mergedProcess = applyPatches(serverProcess, localChanges);

    return new MergedProcessResult(mergedProcess);
};
