/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import * as _ from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission, TenantResource, EnvironmentResource, ResourceCollection, RunbooksDashboardItemResource, NonVcsRunbookResource, TenantedDeploymentMode, TriggerActionCategory } from "client/resources";
import { repository } from "clientInstance";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { QueryStringFilters, IQuery } from "components/QueryStringFilters/QueryStringFilters";
import { Feature, FeatureToggle } from "components/FeatureToggle";
import { TenantMultiSelect, TenantTagMultiSelect, EnvironmentMultiSelect } from "components/MultiSelect";
import { FilterSection } from "components/AdvancedFilterLayout";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { isEqual } from "lodash";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import PaperLayout from "components/PaperLayout";
import { RunbooksWelcomeOnboarding, NewFeatureText } from "./RunbooksOnboarding";
import ActionButton, { NavigationButton, NavigationButtonType, ActionButtonType } from "components/Button";
import Callout, { CalloutType } from "components/Callout";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import InternalLink from "components/Navigation/InternalLink";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import routeLinks from "routeLinks";
import { NoResults } from "components/NoResults/NoResults";
import PagingDataTable from "components/PagingDataTable";
import { EnvironmentChip, TenantChip } from "components/Chips";
import InternalNavLink from "components/Navigation/InternalNavLink";
import { GetTaskRunDashboardItemsListArgs } from "client/repositories/progressionRepository";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { Select } from "components/form";
import RunbookMultiSelect from "components/MultiSelect/RunbookMultiSelect";
import classNames = require("classnames");
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { NextScheduleRunsTitle } from "./NextScheduledRuns";
const styles = require("./OperationsOverviewLayout.less");
import moment from "moment";

export const RunbooksNewFeatureCallout: React.FC = () => {
    return (
        <RunbookTemporaryCallout>
            <Callout type={CalloutType.NewFeature} title={"New Feature"}>
                {NewFeatureText} We'd love to hear <ExternalLink href="OperationsFeedbackForm">your feedback</ExternalLink>.
            </Callout>
        </RunbookTemporaryCallout>
    );
};

export const RunbookTemporaryCallout: React.FC = ({ children }) => {
    const isBeforeCutoff = moment().isSameOrBefore(new Date(2020, 2, 1));
    return <>{isBeforeCutoff && children}</>;
};

interface OperationsOverviewLayoutFilter {
    environmentIds: string[];
    runbookIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

interface OperationsOverviewLayoutQuery extends IQuery {
    environmentIds: string[];
    runbookIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}

class FilterLayout extends AdvancedFilterLayout<OperationsOverviewLayoutFilter> {}

const OperationsOverviewQueryStringFilters = QueryStringFilters.For<OperationsOverviewLayoutFilter, OperationsOverviewLayoutQuery>();

class OperationsOverviewTable extends PagingDataTable<RunbooksDashboardItemResource> {}

interface OperationsOverviewLayoutState extends DataBaseComponentState {
    hasAtLeastOneRunbook?: boolean;
    associatedRunbooks: NonVcsRunbookResource[];
    runbookRunsDashboardItems: ResourceCollection<RunbooksDashboardItemResource> | null;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    runbooks: NonVcsRunbookResource[];
    filter: OperationsOverviewLayoutFilter;
    queryFilter?: OperationsOverviewLayoutFilter;
    totalTriggers: number;
}

type OperationsOverviewLayoutProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps;
const refreshIntervalInMs = 15000;

class OperationsOverviewLayoutInternal extends DataBaseComponent<OperationsOverviewLayoutProps, OperationsOverviewLayoutState> {
    constructor(props: OperationsOverviewLayoutProps) {
        super(props);
        this.state = {
            hasAtLeastOneRunbook: null!,
            associatedRunbooks: [],
            runbookRunsDashboardItems: null,
            tenants: [],
            tagIndex: null!,
            filter: createEmptyFilter(),
            environments: [],
            runbooks: [],
            totalTriggers: 0,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState(await this.getData());
            await this.startRefreshLoop(this.getData, refreshIntervalInMs, false);
        });
    }

    async reloadRuns() {
        await this.doBusyTask(async () => {
            this.setState(await this.getData());
        });
    }

    getData = async () => {
        const project = this.props.projectContext.state.model;
        if (!project) {
            return null;
        }

        const [tenants, tagIndex, environments, runbooks, runbookRunsDashboardItems, triggersResponse] = await Promise.all([
            repository.Tenants.all(),
            tenantTagsets.getTagIndex(),
            repository.Environments.all(),
            repository.Projects.getRunbooks(project, { skip: 0, take: 30 }),
            repository.Progression.getTaskRunDashboardItemsForProject(project, {
                projectIds: [project.Id],
                environmentIds: this.state.filter.environmentIds,
                tenantIds: this.state.filter.tenantIds,
                runbookIds: this.state.filter.runbookIds,
            }),
            repository.Projects.getTriggers(project, 0, 0, null!, TriggerActionCategory.Runbook),
        ]);

        const distinctRunbookIds = Array.from(new Set(runbookRunsDashboardItems.Items.map((x) => x.RunbookId)));
        const associatedRunbooks = await repository.Runbooks.all({ ids: distinctRunbookIds });

        return {
            runbookRunsDashboardItems,
            associatedRunbooks,
            runbooks: runbooks.Items,
            hasAtLeastOneRunbook: runbooks.Items.length > 0,
            tenants,
            tagIndex,
            environments,
            totalTriggers: triggersResponse.TotalResults,
        };
    };

    render() {
        const project = this.props.projectContext.state.model;
        if (!project || !this.state.runbookRunsDashboardItems) {
            return <PaperLayout busy={true} errors={this.errors} />;
        }

        if (!this.state.hasAtLeastOneRunbook) {
            const goToRunbooksButton = (
                <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
                    <NavigationButton type={NavigationButtonType.Primary} label="Go to Runbooks" href={routeLinks.project(project.Slug).operations.runbooks} disabled={false} />
                </PermissionCheck>
            );
            const showOnboarding = this.state.hasAtLeastOneRunbook !== null && !this.state.hasAtLeastOneRunbook;
            return (
                <PaperLayout title={"Overview"} busy={this.state.busy} errors={this.errors} sectionControl={goToRunbooksButton}>
                    <RunbooksNewFeatureCallout />
                    {showOnboarding && <RunbooksWelcomeOnboarding />}
                </PaperLayout>
            );
        }

        const filterSections: FilterSection[] = [
            {
                render: (
                    <div>
                        <RunbookMultiSelect
                            items={this.state.runbooks}
                            value={this.state.filter.runbookIds}
                            onChange={(x) => {
                                this.setFilterState({ runbookIds: x }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                        />
                        <EnvironmentMultiSelect
                            items={this.state.environments}
                            value={this.state.filter.environmentIds}
                            onChange={(x) => {
                                this.setFilterState({ environmentIds: x }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                        />
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <TenantMultiSelect
                                    value={this.state.filter.tenantIds}
                                    items={this.state.tenants}
                                    onChange={(x) => {
                                        this.setFilterState({ tenantIds: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                                <TenantTagMultiSelect
                                    value={this.state.filter.tenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={(x) => {
                                        this.setFilterState({ tenantTags: x }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }}
                                />
                            </PermissionCheck>
                        </FeatureToggle>
                    </div>
                ),
            },
        ];

        const selectedEnvironmentId = this.state.filter.environmentIds && this.state.filter.environmentIds.length > 0 ? this.state.filter.environmentIds[0] : null;
        const selectedRunbookId = this.state.filter.runbookIds && this.state.filter.runbookIds.length > 0 ? this.state.filter.runbookIds[0] : null;
        const nextScheduledElement = this.renderNextScheduled();
        return (
            <PaperLayout title={"Overview"} busy={this.state.busy} errors={this.errors}>
                <OperationsOverviewQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilter} getFilter={this.getFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
                <RunbooksNewFeatureCallout />
                <FilterLayout
                    filterSections={filterSections}
                    filter={this.state.filter}
                    queryFilter={this.state.queryFilter}
                    defaultFilter={createEmptyFilter()}
                    initiallyShowFilter={this.isFiltering()}
                    additionalHeaderFilters={[
                        <Select
                            label=""
                            placeholder="Filter by Runbook"
                            allowFilter={true}
                            allowClear={true}
                            value={selectedRunbookId!}
                            onChange={(x) => {
                                this.setFilterState({ runbookIds: x ? [x] : null! }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                            items={this.state.runbooks.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                            className={styles.filterControl}
                        />,
                        <Select
                            label=""
                            placeholder="Filter by Environment"
                            autoFocus={true}
                            allowFilter={true}
                            allowClear={true}
                            value={selectedEnvironmentId!}
                            onChange={(x) => {
                                this.setFilterState({ environmentIds: x ? [x] : null! }, async () => {
                                    await this.onFilterChange();
                                });
                            }}
                            items={this.state.environments.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                            className={styles.filterControl}
                        />,
                    ]}
                    onFilterReset={(filter: OperationsOverviewLayoutFilter) => {
                        this.setState({ filter }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null as any };
                            this.props.history.replace(location);
                        });
                    }}
                    renderContent={() => (
                        <SidebarLayout sideBar={nextScheduledElement} hideTopDivider={true}>
                            {this.state.runbookRunsDashboardItems && (
                                <OperationsOverviewTable
                                    initialData={this.state.runbookRunsDashboardItems}
                                    additionalRequestParams={this.getAdditionalRequestParams()}
                                    onRow={(item: any) => this.buildRunbookRunRow(item)}
                                    onFilter={this.filter}
                                    headerColumns={["", "", "", ""]} // DO NOT REMOVE, this is needed so the headerColumn classes are correctly populated for our spacing.
                                    headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn, styles.headerColumn]}
                                    hideHeader={false}
                                    onEmpty={this.handleOnEmpty}
                                    filterSearchEnabled={false} // This component has advanced filtering instead.
                                    apiSearchParams={["partialName"]}
                                    filterHintText="Filter by name..."
                                    empty={<NoResults />}
                                    headerRowClassName={styles.resultsTableHeaderRow}
                                />
                            )}
                        </SidebarLayout>
                    )}
                />
            </PaperLayout>
        );
    }

    private renderNextScheduled() {
        const project = this.props.projectContext.state.model;
        const hasTriggers = this.state.totalTriggers > 0;
        const pluralized = this.state.totalTriggers === 1 ? "" : "s";
        return (
            <PermissionCheck permission={Permission.TriggerView} project={project.Id}>
                <>
                    <NextScheduleRunsTitle />
                    <div className={styles.nextScheduledRow}>
                        {hasTriggers && (
                            <InternalLink to={routeLinks.project(project.Slug).operations.triggers}>
                                {this.state.totalTriggers} trigger{pluralized} found
                            </InternalLink>
                        )}
                        {!hasTriggers && (
                            <div>
                                No triggers are currently setup for runbooks.{" "}
                                <PermissionCheck permission={Permission.TriggerCreate} project={project.Id}>
                                    <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTriggers.new}>Create a trigger now</InternalLink>.
                                </PermissionCheck>
                            </div>
                        )}
                    </div>
                </>
            </PermissionCheck>
        );
    }

    private buildRunbookRunRow(runbookRunItem: RunbooksDashboardItemResource) {
        const project = this.props.projectContext.state.model;
        const runbook = this.state.associatedRunbooks.find((x) => x.Id === runbookRunItem.RunbookId);
        const environment = this.state.environments.find((x) => x.Id === runbookRunItem.EnvironmentId);
        let tenant: TenantResource | null | undefined;

        if (runbook!.MultiTenancyMode !== TenantedDeploymentMode.Untenanted) {
            tenant = this.state.tenants.find((x) => x.Id === runbookRunItem.TenantId);
        }
        return [
            <RunbookTaskStatusDetails project={project} item={runbookRunItem} />,
            <div className={classNames(styles.customListItem)}>
                <InternalLink to={routeLinks.project(project.Slug).operations.runbook(runbook!.Id).root}>{runbook!.Name}</InternalLink>
            </div>,
            <>
                {environment && <EnvironmentChip environmentName={environment.Name} />}
                {tenant && <TenantChip tenantName={tenant.Name} />}
            </>,
            <div className={styles.runBy}>Run by {runbookRunItem.RunBy}</div>,
        ];
    }

    private handleOnEmpty = () => {
        const project = this.props.projectContext.state.model;
        return (
            <div className={styles.emptyCell}>
                No runs found.&nbsp;<InternalNavLink to={routeLinks.project(project.Slug).operations.runbooks}>Go to Runbooks</InternalNavLink>
            </div>
        );
    };

    private getAdditionalRequestParams(): Map<keyof GetTaskRunDashboardItemsListArgs, any> {
        const project = this.props.projectContext.state.model;
        const additionalRequestParams = new Map<keyof GetTaskRunDashboardItemsListArgs, any>();
        additionalRequestParams.set("projectIds", [project.Id]);
        additionalRequestParams.set("environmentIds", this.state.filter.environmentIds);
        additionalRequestParams.set("runbookIds", this.state.filter.runbookIds);
        additionalRequestParams.set("tenantIds", this.state.filter.tenantIds);
        return additionalRequestParams;
    }

    private filter(filter: string, resource: RunbooksDashboardItemResource) {
        return !filter || filter.length === 0 || !resource || resource.RunbookSnapshotName.toLowerCase().includes(filter.toLowerCase());
    }

    private setFilterState<K extends keyof OperationsOverviewLayoutFilter>(state: Pick<OperationsOverviewLayoutFilter, K>, callback?: () => void) {
        this.setState(
            (prev) => ({
                filter: { ...(prev!.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }

    private async onFilterChange() {
        await this.reloadRuns();
    }

    private queryFromFilter = (filter: OperationsOverviewLayoutFilter): OperationsOverviewLayoutQuery => {
        const query: OperationsOverviewLayoutQuery = {
            environmentIds: filter.environmentIds,
            runbookIds: filter.runbookIds,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags,
        };

        return query;
    };

    private getFilter = (query: OperationsOverviewLayoutQuery): OperationsOverviewLayoutFilter => {
        const filter: OperationsOverviewLayoutFilter = {
            ...createEmptyFilter(),
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            runbookIds: arrayValueFromQueryString(query.runbookIds) || [],
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names
        };

        return filter;
    };
}

function createEmptyFilter(): OperationsOverviewLayoutFilter {
    return {
        environmentIds: [],
        runbookIds: [],
        tenantIds: [],
        tenantTags: [],
    };
}

export default withProjectContext(OperationsOverviewLayoutInternal);
